import { useParams } from "react-router-dom";
import Header from "../../components/header/Header";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import Footer from "../../components/footer";
import Markdown from "react-markdown";
import gsap from 'gsap';
import { Helmet, HelmetProvider } from "react-helmet-async";


export default function Article() {
    const { id } = useParams()
    const { loading, error, data } = useFetch(`https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com/api/blogs/${id}?populate=*`);
    const [article, setArticle] = useState(null)

    useEffect(() => {
      if (document.querySelector('.article')) {
      gsap.to('.easeArticle', {
        opacity: 1,
        duration: 1,
        delay: 0.5,
        ease: 'power1.inOut',
        stagger: 0.3,
      })}
    })

    useEffect(() => {
        if (data && data.data) {
            setArticle(data.data);
        }
    }, [data]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading article: {error}</p>;
    if (!article) return <p>Article not found.</p>;

    const imageUrl = article.attributes.Image?.data?.[0]?.attributes?.url;
    const title = article.attributes.Title;
    const texte = article.attributes.Texte;
    const youtubeURL = article.attributes.YoutubeURL;
    const convertYouTubeLinkToEmbed = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
      
        if (match && match[2].length === 11) {
          return `https://www.youtube.com/embed/${match[2]}`;
        }
      
        return null;
      };
      
      const youtubeEmbedURL = youtubeURL ? convertYouTubeLinkToEmbed(youtubeURL) : null;

      
    return ( 
      <div className="flex flex-col min-h-screen article">
        <HelmetProvider>
        <Helmet>
          <title>Julie Demina | {title}</title>
          <meta name="description" content="Julie Demina's blog article" />
          <link rel="canonical" href="https://juliedemina.com/blog" />
          <meta property="og:url" content="https://juliedemina.com/blog" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Julie Demina | Blog" />
          <meta property="og:description" content="Julie Demina's blog article" />
          <meta property="og:image" content={imageUrl} />
        </Helmet>
        </HelmetProvider>
      <div className="flex-grow">
            <Header delay={0}/>

            <div className="flex flex-col justify-center items-center w-full mt-5 px-5 sm:px-10 gap-5">

            {imageUrl && (
            <div className="w-full md:w-1/2 opacity-0 easeArticle">
              <img src={`${imageUrl}`} alt="Photo" className="w-full object-cover"/>
            </div> )}

            {youtubeEmbedURL && (
            <div className="w-full md:w-1/2 opacity-0 easeArticle">
            <div className="aspect-w-16 aspect-h-9">
            <iframe 
            src={youtubeEmbedURL}
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            allowFullScreen 
            className=" w-full h-full"
            />
            </div>
            </div> )}

            <div className="w-full md:w-1/2 flex flex-col gap-5">
                  <Markdown className="font-inter font-bold text-black text-left opacity-0 easeArticle">{title}</Markdown>
                  <Markdown className="text-black text-normal font-normal font-inter text-left whitespace-pre-wrap opacity-0 easeArticle">{texte}</Markdown>
             </div>
            
            </div>
            </div>

            <Footer />

        </div>
    )
}