import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header'
import useFetch from '../../hooks/useFetch'
import styles from './styles.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCoverflow, Navigation  } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation'; 
import 'swiper/css/autoplay';
import gsap from 'gsap'
import Loading from '../../components/UI/loading/loading';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


function Gallery() {
  const { loading, error, data } = useFetch('https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com/api/collections?populate[gallery_images][populate]=*')
  const [collections, setCollections] = useState([])
  const navigate = useNavigate();


  useEffect(() => {
    if (data && data.data) {
      setCollections(data.data);
      // console.log("this is the data", data.data);
    }
  }, [data]);

  useEffect(() => {
    if (document.querySelector('.gallery')) {
    gsap.to('.gallery', {
      opacity: 1,
      duration: 1,
      ease: 'power1.inOut',
      delay: 0.5,
      stagger: 0.3,
      scale: 1,
    })}

    gsap.to('.arrow', {
      opacity: 1,
      duration: 1,
      ease: 'power1.inOut',
      delay: 2,
    })
    
  })

  if (loading) {
    <Loading />
  }
  if (error) {
    console.log(error);
  }


  return (

    <div className="h-[calc(100dvh)] flex flex-col overflow-hidden gallery">
      <HelmetProvider>
      <Helmet>
        <title>Julie Demina | Gallery</title>
        <meta name="description" content="Julie Demina's gallery page" />
        <link rel="canonical" href="https://juliedemina.com/gallery" />
        <meta property="og:url" content="https://juliedemina.com/gallery" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Julie Demina | Gallery" />
        <meta property="og:description" content="Julie Demina's gallery page" />
        <meta property="og:image" content="https://julie-demina.s3.eu-west-3.amazonaws.com/Bold_Brand_Name_Initials_Signature_Typography_Logo_6_be5d576e75.png" />
      </Helmet>
      </HelmetProvider>

  <Header />

  <div className="flex-grow flex justify-center items-center">
  <Swiper
    modules={[EffectCoverflow, Navigation, Autoplay]}
    effect="coverflow"
    coverflowEffect={{
      rotate: 0,
      stretch: 100,
      depth: 1500, // Valeur par défaut pour les écrans plus grands
      modifier: 1,
      slideShadows: false,
    }}
    breakpoints={{
      320: { // Pour les très petits écrans, si nécessaire
        coverflowEffect: {
          depth: 50, // Vous pouvez ajuster cette valeur comme souhaité
        },
      },
      768: {
        coverflowEffect: {
          depth: 100, // Valeur pour les écrans moyens, comme les tablettes
        },
      },
      1024: {
        coverflowEffect: {
          depth: 1000, // Valeur pour les écrans plus grands, mais pas encore desktop
        },
      },
      1280: {
        coverflowEffect: {
          depth: 1500, // Valeur pour les écrans desktop
        },
      },
    }}
    spaceBetween={200}
    grabCursor={true}
    centeredSlides={true}
    loop={false}
    loading="lazy"
    lazyPreloadPrevNext={2}
    slidesPerView='auto'
    className="w-full flex flex-col justify-center items-center h-fit"
    navigation={{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }}
    // autoplay={{
    //   delay: 5000,
    //   disableOnInteraction: false,
    // }}
  >


    <div className='hidden xl:block opacity-0 arrow'>
    <div className="swiper-button-next hover:scale-110 transition-all duration-300 ease-in-out"></div>
    </div>

    <div className="absolute bottom-0 left-0 right-0 flex xl:hidden justify-between pb-12 opacity-0 arrow">
          <div className="swiper-button-prev custom-swiper-button"></div>
          <div className="swiper-button-next custom-swiper-button"></div>
        </div>


        {
  collections.filter(collection => collection.attributes.gallery_images.data.length > 0 && collection.attributes.gallery_images.data[0].attributes.Image.data.length > 0).map((collection, index) => (
      <SwiperSlide key={collection.id} className="gallerySlide" >
      <div className='flex flex-col items-center w-full'>
        {/* Collection Title */}
        <span className="text-black text-sm font-medium uppercase text-center opacity-0 gallery mb-1">Collection</span>
        {/* Landscape Title */}
        <h1 className="text-black text-4xl sm:text-6xl font-medium uppercase text-center mb-5 opacity-0 gallery">{collection.attributes.Title}</h1>
        
        {/* Image */}
        <div className="w-full flex justify-center opacity-0 gallery px-3 sm:px-0">
        {collection.attributes.gallery_images.data.length > 0 &&
          collection.attributes.gallery_images.data[0].attributes.Image.data.length > 0 && (
          <LazyLoadImage
            src={collection.attributes.gallery_images.data[0].attributes.Image.data[0].attributes.formats.large.url}
            placeholderSrc={collection.attributes.gallery_images.data[0].attributes.Image.data[0].attributes.formats.thumbnail.url}
            alt="Photo"
            loading="lazy"
            onClick={() => navigate(`/gallery/${collection.attributes.Title}`)}
            className="object-cover w-auto max-w-full h-auto sm:min-h-[50vh] cursor-pointer shadow-lg border-2 border-black"
            style={{ maxHeight: 'calc(40vh + 100px)' }}
            effect='blur'
          />
        )}
        </div>
        
        {/* Description Text */}
        <div className='w-full px-4 text-center md:w-1/2 opacity-0 gallery'>
          <p className="text-black font-inter text-sm mt-4">
          {collection.attributes.Description}
          </p>
        </div>
      </div>
    </SwiperSlide>
    
    ))}

      {/* <SwiperSlide className="flex flex-col justify-center items-center h-fit" style={styles.swiperSlide}>
        <img 
        src={require('../../images/homeimage2.png')} 
        className='max-w-full sm:max-w-[70%] xl:max-w-[60%] 2xl:max-w-[57%]'
        alt="Photo"
        />
        <p className="text-center text-black text-sm font-normal font-inter mt-2">Picture taken in Nice, France.</p>
      </SwiperSlide>

      <SwiperSlide className="flex flex-col justify-center items-center h-fit" style={styles.swiperSlide}>
        <img 
        src={require('../../images/homeimage.png')} 
        className='max-w-full sm:max-w-[70%] xl:max-w-[60%] 2xl:max-w-[57%]'
        alt="Photo"
        />
        <p className="text-center text-black text-sm font-normal font-inter mt-2">Picture taken in Nice, France.</p>
      </SwiperSlide>

      <SwiperSlide className="flex flex-col justify-center items-center h-fit" style={styles.swiperSlide}>
        <img 
        src={require('../../images/homeimage.png')} 
        className='max-w-full sm:max-w-[70%] xl:max-w-[60%] 2xl:max-w-[57%]'
        alt="Photo"
        />
        <p className="text-center text-black text-sm font-normal font-inter mt-2">Picture taken in Nice, France.</p>
      </SwiperSlide> */}

      <div className='hidden xl:block opacity-0 arrow'>
      <div className="swiper-button-prev hover:scale-110 transition-all duration-300 ease-in-out"></div>
      </div>
        
  </Swiper>
</div>
      
</div>

  )
}

export default Gallery