

export default function Hamburger({isOpen, setIsOpen, isClicked, setIsClicked}) {
    const handleClick = () => {
        setIsOpen(!isOpen);
        setIsClicked(!isClicked);
    }
    
    return (
        <div className="hamburger flex flex-col justify-center items-center gap-1.5" onClick={handleClick} style={{ zIndex: '50'}}>
            <div className={`h-0.5 w-6 bg-black transform duration-300 ease-in-out ${isOpen ? "rotate-45 translate-y-1.5 bg-white" : ""}`} style={{ transformOrigin: '43%', zIndex: '50' }} ></div>
            <div className={`h-0.5 w-6 bg-black transform duration-300 ease-in-out ${isOpen ? "opacity-0 " : "opacity-100"}`} style={{ transitionDelay: isOpen ? '0s' : '0.1s', zIndex: '50' }}></div>
            <div className={`h-0.5 w-6 bg-black transform duration-300 ease-in-out ${isOpen ? "-rotate-45 -translate-y-1.5 bg-white" : ""}`} style={{ transformOrigin: '35%', zIndex: '50' }}></div>
        </div>
    )
}