import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './home.css'
import useFetch from '../../hooks/useFetch';
import gsap from 'gsap';
import Header from '../../components/header/Header';
import LoadingIntro from '../../components/loadingIntro';
import Loading from '../../components/UI/loading/loading';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



function Home() {
  const { loading, error, data } = useFetch('https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com/api/home-images?populate=*')
  const [images, setImages] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0); 

  
  useEffect(() => {
    if (data && data.data) {
      setImages(data.data);
      // console.log("this is the data", data.data);
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (!images.length) return prevIndex;
  
        const newIndex = (prevIndex + 1) % images.length;
        return newIndex;
      });
    }, 5000); 
  
    return () => {
      clearInterval(interval);
    };
  }, [images.length]); 
  

  useEffect(() => {
    if (document.querySelector('.home')) {
    gsap.to('.ease', {
      opacity: 1,
      duration: 1,
      ease: 'power1.inOut',
      delay: 2,
    });

    gsap.to('.img', {
      scale: 1,
      opacity: 1,
      duration: 1,
      ease: 'power1.inOut',
      delay: 2,
    });

    gsap.to('.title', {
      y: 0,
      duration: 1,
      ease: 'power1.inOut',
      delay: 2.2,
      opacity: 1,
    });

    gsap.to('.links', {
      opacity: 1,
      duration: 1,
      ease: 'power1.inOut',
      delay: 2.7,
      stagger: 0.2,
    });
  }
  });

  const year = new Date().getFullYear().toString();

  if (loading) return <Loading />;
  if (error) return <p>Error </p>;
  if (!images.length) return null;

  return (
    <div className="h-[calc(100dvh)] flex flex-col overflow-hidden home" style={styles.home}>
      <HelmetProvider>
      <Helmet>
        <title>Julie Demina - Photography</title>
        <meta name="description" content="Julie Demina Photography specializes in capturing timeless and beautiful moments. Explore my portfolio to find the perfect photography service for you." />
        <link rel="canonical" href="https://juliedemina.com" />
        <meta property="og:url" content="https://juliedemina.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Julie Demina - Photography" />
        <meta property="og:description" content="Julie Demina Photography specializes in capturing timeless and beautiful moments. Explore my portfolio to find the perfect photography service for you." />
      </Helmet>
      </HelmetProvider>
      <LoadingIntro />

    <div className="hidden absolute left-0 z-30 xl:flex justify-center items-center h-full text-black text-base font-normal font-bodoni underline italic pl-24 opacity-0 ease">
    <Link to="/gallery" className="hover:scale-105 transition duration-300 ease-in-out cursor-pointer">explore</Link>
    </div>


    {/* Navigation Section */}
   

    

  <div className='block md:hidden'>
    <Header className="block md:hidden" delay={2}/>
    </div>

  <header className="hidden md:flex justify-center items-center w-full pt-10 xl:pt-14">
    <div className="flex flex-row justify-between items-center z-50 px-20 2xl:px-0 w-full 2xl:w-4/6">
      <h1 className="text-center text-black text-4xl md:text-5xl font-medium font-dmsans uppercase opacity-0 title transform -translate-y-48">Julie Demina</h1>
      {/* <Hamburger /> */}
      <div className="flex justify-between items-center gap-10">
      <ul className="flex flex-row gap-10 text-right text-black text-sm sm:text-base font-medium font-inter uppercase ">
        <li><Link to="/" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left opacity-0 links">Home</Link></li>
        <li><Link to="/gallery" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left opacity-0 links">Gallery</Link></li>
        <li><Link to="/blog" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left opacity-0 links">Blog</Link></li>
        <li><Link to="/about" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left opacity-0 links">About</Link></li>
      </ul>
      </div>
    </div>
    </header>

  <div className="flex justify-center items-center h-full" style={styles.home}>

  {images.length > 0 && images[currentIndex].attributes.Image.data && images[currentIndex].attributes.Image.data.length > 0 && (
    // <img 
        //   src={`${images[currentIndex].attributes.Image.data[0].attributes.url}`} 
        //   alt={'image'} 
        //   className='max-h-fit sm:max-h-custom w-full sm:w-auto h-full sm:h-auto opacity-0 img z-30 object-cover scale-95 img'
        //   loading='lazy'
        // />
    
    <div className='h-full flex justify-center scale-95 img opacity-0 img-container cursor-pointer'>
  <Link to="/gallery">
        <LazyLoadImage
          src={`${images[currentIndex].attributes.Image.data[0].attributes.url}`}
          alt={'image'} 
          className='max-h-fit sm:max-h-custom w-full sm:w-auto h-full sm:h-auto img z-30 object-cover '
          placeholderSrc={`${images[currentIndex].attributes.Image.data[0].attributes.formats.thumbnail.url}`}
          loading='lazy'
        />
        </Link>
        </div>
        )}

    {/* {images.map((image, index) => (
      <img 
          src={`https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com${image.attributes.Image.data[0].attributes.url}`} 
          alt={image.attributes.Image.data[0].attributes.alternativeText} 
          className="h-full md:max-h-[70%] max-w-full sm:max-w-[70%] xl:max-w-[60%] 2xl:max-w-[60%] object-cover opacity-0 img z-30" key={image.id} id={image.id}
          />
          ))} */}

        {/* <img src={require('../../images/homeimage.png')} alt="Photo" className="h-full md:max-h-[70%] max-w-full sm:max-w-[70%] xl:max-w-[60%] 2xl:max-w-[60%] object-cover opacity-0 img z-30" /> */}

    </div>

    <div className="flex justify-center items-center w-full pt-3 md:pt-0 pb-3 md:pb-14 border-t-2 border-black sm:border-none">
    <div className="flex justify-between items-start px-5 md:px-20 2xl:px-0 w-full 2xl:w-4/6 opacity-0 title transform translate-y-20">
    <h2 className="w-full md:w-2/4 text-sm text-center sm:text-left"><span className="text-black text-sm font-medium font-inter">Immerse yourself in a visual journey where every image narrates unique and timeless story.</span></h2>
    <div className="hidden sm:block text-black text-sm font-medium font-inter">© {year} Julie Demina</div>
    </div>
    </div>

   

    <div className="hidden absolute right-0 top-0 bottom-0 z-30 xl:flex justify-center items-center text-black text-base font-normal font-bodoni underline italic pr-24 opacity-0 ease">
    <Link to="/gallery" className="hover:scale-105 transition duration-300 ease-in-out cursor-pointer">explore</Link>
    </div>

  </div>
  
  )
}

export default Home