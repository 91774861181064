import React from 'react'
import './styles.css'

function Loading() {
  return (
    <div className="flex justify-center items-center h-[calc(100dvh)]">
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loading