import React, { useState } from 'react'
import './styles.css'

function Success() {
  const [show, setShow] = useState(true);

  // display success message for 5 seconds
  setTimeout(() => {
    setShow(false);
    window.location.reload();
  }, 3000);



  return (
      <div className="wrapper-success" style={{display: show ? 'block' : 'none'}}>
    <div className="container-success">
        <h1 id="text-black text-center font-inter text-4xl font-bold">Thank you for your message!</h1>
    </div>
        </div>
  )
}

export default Success