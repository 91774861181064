import gsap from "gsap";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Footer() {

    useEffect(() => {
        gsap.to('.footer', {
            opacity: 1,
            duration: 1.5,
            ease: 'power1.inOut',
            delay: 0.3, 
            y: 0,
        });
    })

    const year = new Date().getFullYear().toString();

    return (
        <footer className="w-full bg-transparent border-black border-t py-5 mt-10 opacity-0 translate-y-24 footer">
            <div className="flex flex-col sm:flex-row gap-3 sm:gap-10 text-black text-sm font-medium font-inter items-center capitalize justify-center w-full">
            <Link to="/" className="font-bold hover:text-gray-600 transition duration-300 ease-in-out">JULIE DEMINA</Link>
            <Link to="/" exact className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">HOME</Link>
            <Link to="/gallery" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">GALLERY</Link>
            <Link to="/blog" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">BLOG</Link>
            <Link to="/about" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">ABOUT</Link>
            <span className="text-black text-sm font-medium font-inter">© {year} Julie Demina</span>
            </div>
        </footer>
    )
}