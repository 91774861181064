import gsap from "gsap";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function MenuHam({isOpen, setIsOpen}) {
    const closeMenu = () => {
        setIsOpen(false);
    };

    // Empêcher le défilement du corps lorsque le menu est ouvert
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "none";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpen]);

    useEffect(() => {
        gsap.to(".menuHam", {
            y: isOpen ? 0 : "-100%",
            duration: 1,
            ease: "power4.inOut",
            delay: 0,
            onComplete: () => {
                if (!isOpen) {
                    document.body.style.overflow = "auto";
                } else {
                    document.body.style.overflow = "none";
                }
            }
        })    
    }, [isOpen]);
    
  
    return (

        <div className="absolute flex flex-col justify-center items-center z-40 h-[100vh] w-screen bg-black menuHam" style={{ transform: 'translateY(-100%)', zIndex: '40' }}>
            <div className="absolute flex justify-end items-end top-0 w-full pt-6 pr-4" onClick={closeMenu}>
             <div className=" hamburger flex flex-col justify-center items-center gap-1.5 right-0 top-0 bg-white rounded-full h-10 w-10 z-50 hover:bg-gray-300 hover:scale-110 transition duration-300 ease-in-out" style={{ zIndex: '50'}}>
            <div className="h-0.5 w-4 transform duration-300 ease-in-out rotate-45 translate-y-1.5 bg-black" style={{ transformOrigin: '40%', zIndex: '50' }} ></div>
            <div className="h-0.5 w-4 bg-black transform duration-300 ease-in-out opacity-0" style={{ transitionDelay: isOpen ? '0s' : '0.1s', zIndex: '50' }}></div>
            <div className="-rotate-45 -translate-y-1.5 bg-black h-0.5 w-4 transform duration-300 ease-in-out" style={{ transformOrigin: '30%', zIndex: '50' }}></div>
            </div>

            </div>
            <div className="flex flex-col justify-center items-center gap-3 h-full">
                <Link to="/" className="text-white text-4xl font-medium font-dmsans uppercase" onClick={closeMenu}>Julie Demina</Link>
                <div className="flex flex-col justify-center items-center gap-3 text-white text-sm font-medium font-inter">
                    <Link to="/" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left" onClick={closeMenu}>HOME</Link>
                    <Link to="/gallery" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left" onClick={closeMenu}>GALLERY</Link>
                    <Link to="/blog" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left" onClick={closeMenu}>BLOG</Link>
                    <Link to="/about" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-white after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left" onClick={closeMenu}>ABOUT</Link>
                </div>
            </div>
       </div>
    )
}