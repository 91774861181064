import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

// Initialisez ReactGA avec votre ID de suivi GA4
ReactGA.initialize('G-3QMP3VJ2KT');

export default function Analytics() {
  const location = useLocation();

  useEffect(() => {
    // Utilisez send pour envoyer l'information de page vue à GA4
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null; // Ce composant ne rend rien
}
