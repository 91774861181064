import './index.css';
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Gallery from "./pages/gallery/Gallery";
import About from "./pages/about/About";
import Loading from "./components/UI/loading/loading";
import useFetch from "./hooks/useFetch";
import Blog from './pages/blog/Blog';
import Article from './pages/blog/Article';
import ReactGA from "react-ga4";
import Analytics from './components/analytics';
import NotFound from './components/notFound';
import Collection from './pages/gallery/Collection';

ReactGA.initialize('G-3QMP3VJ2KT')


function App() {
  const {loading} = useFetch('https://julie-demina-portfolio-5cb04ae2d960.herokuapp.com')

  if (loading) {
    return <Loading />
  }


  return (
    <Router>
      <Analytics />
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/gallery/:title" element={<Collection/>}/>
        <Route path="/about" element={<About/>}/>
        <Route exact path="/blog" element={<Blog />}/>
        <Route path="/blog/:id" element={<Article />}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
    </Router>
  );
}

export default App;


