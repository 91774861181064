import React, { useEffect, useState } from 'react'
import './styles.css'
import { Link, NavLink, useLocation } from 'react-router-dom'
import gsap from 'gsap'
import Hamburger from '../hamburger'
import MenuHam from '../menuHam'


function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isClicked, setIsClicked] = useState(false);


    const location = useLocation();

    useEffect(() => {

        // Obtenir le délai en fonction de la page
        let delay = 0;
        switch (location.pathname) {
            case "/":
                delay = 2.2; // Délai pour la page gallery
                break;
            default:
                delay = 0; // Pas de délai par défaut
        }

        gsap.to('.header', {
            opacity: 1,
            duration: 1,
            ease: 'power1.inOut',
            delay: delay, // Utiliser le délai en fonction de la page
            y: 0,
        });
    }, [location.pathname]); // Exécuter lorsque l'emplacement change
    
  return (
    <header> 
            <MenuHam isOpen={isOpen} setIsOpen={setIsOpen} />

        <div className="w-full h-fit flex flex-col justify-center items-center pt-3 pb-2 md:pt-5 xl:pt-10 sm:pb-3 transform -translate-y-20 header opacity-0 border-b-2 border-black sm:border-none">
        <div className='flex flex-col justify-center items-center w-fit gap-2 headerNav'>
            <Link to="/"><h1 className="text-center text-black text-4xl md:text-5xl font-medium font-dmsans uppercase titleHeader hover:text-gray-600 transition duration-300 ease-in-out">Julie Demina</h1></Link>
            <Hamburger isOpen={isOpen} setIsOpen={setIsOpen} isClicked={isClicked} setIsClicked={setIsClicked}/>


            <div className="flex flex-row  text-black text-sm font-medium font-inter justify-between w-full nav">
            <Link to="/" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">HOME</Link>
            <NavLink to="/gallery" activeclassname="active" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">GALLERY</NavLink>
            <NavLink to="/blog" activeclassname="active" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">BLOG</NavLink>
            <NavLink to="/about" activeclassname="active" className="relative w-fit block after:block after:content-[''] after:absolute after:h-[2px] after:bg-black after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left">ABOUT</NavLink>
            </div>
       </div>

    </div>
            
    </header>
  )
}


export default Header

