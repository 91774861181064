import Header from "./header/Header";

export default function NotFound() {
    return (
        <div className="h-[calc(100dvh)] flex flex-col">
        <Header />
        <div className="flex flex-col justify-center items-center h-screen text-center">
            <h1 className="text-4xl font-bold">404</h1>
            <h2 className="text-2xl font-bold">Page Not Found</h2>
            <p className="text-base sm:text-lg">Sorry, the page you are looking for does not exist.</p>
        </div>
        </div>
    )
}