import { useEffect } from "react"
import gsap from "gsap"
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function LoadingIntro() {
    useEffect(() => {
        if (document.querySelector('.loadingIntro')) {
        gsap.to("#loading1", {
            duration: 1,
            y: "-100%",
            // ease: "power4.inOut",
            ease: 'circ.inOut',
            delay: 2,
            onComplete: () => {
                const loadingElement = document.getElementById('loading');
                if (loadingElement) {
                  loadingElement.style.display = 'none';
                }

                // at the end of the animation, change the color-theme to white
                
              }
        })

        gsap.to("#loading2", {
            duration: 1,
            y: "100%",
            // ease: "power4.inOut",
            ease: 'circ.inOut',
            delay: 2,
        })

        const titleText = "Julie Demina";
        const title = document.querySelector(".title");
        title.innerHTML = titleText.split("").map(letter => `<span class="letter" style="opacity: 0">${letter}</span>`).join("");

        gsap.to(".letter", {
            duration: 0.5,
            opacity: 1,
            stagger: 0.05,
            ease: "power1.inOut",
        })

        gsap.to(".description", {
            duration: 0.5,
            opacity: 1,
            delay: 1,
            ease: "power1.inOut",
            onComplete: () => {
                // Attendre un moment avant de commencer à masquer l'élément
                gsap.to(".description" & '.letter', {
                    duration: 0.5, // Durée pour disparaître
                    opacity: 0, // Faire disparaître en ajustant l'opacité à 0
                    ease: "power1.inOut",
                    onComplete: () => {
                        // Après la disparition, masquer l'élément
                        const loadingElement = document.getElementById('text');
                        if (loadingElement) {
                            loadingElement.style.display = 'none';
                        }
                    }
                });
            }
        })}
    }, [])
  return (
    <div className="w-full h-[calc(100dvh)] flex flex-col items-center justify-center absolute top-0 left-0 overflow-hidden z-50 loadingIntro" id="loading" >
    <HelmetProvider>
        <Helmet>
            // color theme
            <meta name="theme-color" content="#ffffff" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
    <meta content="#ffffff" data-react-helmet="true" name="theme-color" />
        </Helmet>
    </HelmetProvider>
        <div className="w-full h-2/4 bg-black overflow-hidden" id="loading1"/>
        <div className="flex flex-col justify-center items-center bg-black w-full" id="text">
            <div className="text-white text-4xl md:text-5xl font-medium font-dmsans uppercase title"></div>
            <h2 className="text-white text-base font-medium font-bodoni italic description opacity-0">photography</h2>
        </div>
            <div className="w-full h-2/4 bg-black overflow-hidden" id="loading2"/>
    </div>
  )
}